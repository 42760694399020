import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Card, Col, Row } from 'react-bootstrap';
import Slide from '@mui/material/Slide';
import { forwardRef, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import './walletlogin.css'
import { USDStakeContext } from '../../Contexts/USDContext';

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="top" ref={ref} {...props} />;
});


export default function WalletLoginWaring({ open, setOpen }) {
    const { connectToMetamask, openWalletModal, openWalletModalWarning, connectToCoinbase, closeWalletModalWarning, walletWarning, connectToWalletConnect, connectToTrustWallet
    } = useContext(USDStakeContext);

    return (
        <div>

            <Dialog
                open={walletWarning}
                TransitionComponent={Transition}
                keepMounted
                onClose={closeWalletModalWarning}
                aria-describedby="alert-dialog-slide-description"
            >
                <div className="dialogWallet p-3" style={{ backgroundColor: '#060C13' }}>
                    <DialogTitle className='text-center alertWalletDiv mx-auto' style={{ color: 'white' }}>
                        Please Login To Access Dashboard
                    </DialogTitle>
                    <DialogContent className="contentDiv text-center pt-2" style={{ color: 'white' }}>
                        <DialogContentText id="alert-dialog-slide-description" className='text-warning'>
                            Tap to Login
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions className='text-center alertWalletDiv mx-auto d-flex justify-content-center align-items-center' style={{ gap: "10px" }}>
                        <Card.Img variant="top" style={{ width: "50px" }} src="/assets/metamask.png" className="maskImg" onClick={() => {
                            openWalletModal();
                            closeWalletModalWarning();
                        }} />
                        <Card.Img variant="top" style={{ width: "50px" }} src="/assets/trust.png" className="maskImg " onClick={() => {
                            connectToTrustWallet();
                            closeWalletModalWarning();
                        }} />
                        {/* <Card.Img variant="top" style={{ width: "50px" }} src="/assets/walletc.png" className="maskImg " onClick={() => {
                            connectToWalletConnect();
                            closeWalletModalWarning();
                        }} /> */}
                        <Card.Img variant="top" style={{ width: "50px" }} src="/assets/walletl.png" className="maskImg" onClick={() => {
                            connectToCoinbase();
                            closeWalletModalWarning();
                        }} />
                        {/* <button className='btn btn-danger btn-sm' onClick={closeWalletModalWarning}>Cancel</button>
                        <button className='btn btn-success btn-sm' onClick={() => {
                            openWalletModal();
                            closeWalletModalWarning();
                        }}>Login</button>
                        <button className='btn btn-success btn-sm' onClick={() => {
                            openWalletModal();
                            closeWalletModalWarning();
                        }}>Login</button>
                        <button className='btn btn-success btn-sm' onClick={() => {
                            openWalletModal();
                            closeWalletModalWarning();
                        }}>Login</button>
                        <button className='btn btn-success btn-sm' onClick={() => {
                            openWalletModal();
                            closeWalletModalWarning();
                        }}>Login</button> */}
                    </DialogActions>
                </div>
            </Dialog>


        </div>
    );
}