import React from 'react';
import './Footer.css';

const Footer = () => {
    return (
        <section className='bg-footer'>

            <div className='container text-dark' style={{ paddingTop: "70px" }}>
                <div className='mx-auto text-center pb-3'>
                    <img src="./logo512.png" alt="" className='img-fluid' width={120} /></div>

                <div className='mx-auto text-center textWidth my-3'><p className='fw-bold'>UTI [UNITIC] on Polygon Network Due To Faster Transaction speed, Lower Gas Fees, Polygon Network Compatibility, Smart Contract Compatibility</p></div>

                {/* second  */}
                <div className='pt-4'>
                    <hr style={{ color: "black", border: "1px solid black", background: "black" }} />
                    <div className='d-flex justify-content-between align-items-center flex-lg-row flex-column pt-4 pb-5'>
                        <div>
                            {/* <img src="./logo.png" alt="" className='img-fluid' width={180} /> */}
                            <img src="./assets/footerv3.png" alt="" className='img-fluid' width={180} />
                        </div>
                        <div className='text-center'>
                            <p className='mb-0'>Connect With Us</p>
                            <div className='d-flex justify-content-center align-items-center mt-2' style={{ gap: "8px" }}>
                                <img src="/assets/siconv21.png" alt="" className='img-fluid' />
                                <img src="/assets/siconv22.png" alt="" className='img-fluid ms-3' />
                                {/* <span className='pt-1'><i class="fas fa-envelope"></i></span>  <a href="mailto:support@intrust.world">support@intrust.world</a> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </section>
    );
};

export default Footer;