import React, { useEffect } from 'react';
import IntroSection from './IntroSection';
import StepsToStake from './StepsToStake';
import AffiliatePart from './AffiliatePart';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-hot-toast';
import SuperDapps from './SuperDapps';
import AskQue from './AskQue';
import Home from './Home';

const HomeAffiliate = () => {
    const { affiliateLink } = useParams();
    const navigate = useNavigate();
    // console.log(affiliateLink);
    useEffect(() => {
        const data = async () => {
            if (affiliateLink) {
                await axios.get("https://api.utistaking.com/api/v1/wallet-user/all")
                    .then(res => {
                        if (res.status === 200) {
                            const finding = res.data.find(item => item?.myReferralCode === `https://www.utistaking.com/${affiliateLink}`);
                            if (finding) {
                                sessionStorage.setItem("affiliate", `https://www.utistaking.com/${affiliateLink}`);
                            }
                            else {
                                sessionStorage.removeItem("affiliate");
                                toast.error("Your affiliate link is invalid!");
                                navigate("/");
                            }
                        }
                    })

            }
            else {
                sessionStorage.removeItem("affiliate");
            }
        }
        data();
    }, [affiliateLink]);

    return (
        <>
            <Home />
        </>
    );
};

export default HomeAffiliate;