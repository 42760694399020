import React, { useContext, useEffect, useState } from 'react';
import { Button, Container } from 'react-bootstrap';
import './IntroSection.css';
import axios from 'axios';
import { USDStakeContext } from '../../Contexts/USDContext';
import { useNavigate } from 'react-router-dom';

const IntroSection = () => {
    const [latestUserSign, setLatestUserSign] = useState([]);
    const { user, openWalletModal, openWalletModalWarning } = useContext(USDStakeContext);
    const navigate = useNavigate();

    useEffect(() => {
        const fetching = async () => {
            await axios.get("https://api.utistaking.com/api/v1/wallet-user/latest-login")
                .then(res => {
                    if (res.status === 200) {
                        setLatestUserSign(() => res.data?.data[0]);
                    }
                })
                .catch((e) => {
                    console.log(e);
                })
        }
        fetching();
    }, []);

    // const timeString1 = "2023-05-21T06:58:53.679Z";
    const timeString1 = latestUserSign?.updatedAt;
    const timeString2 = new Date().toLocaleTimeString('en-IN', { timeZone: 'Asia/Kolkata' });
    // console.log(timeString2);
    // const timeString2 = "3:12:20 pm";

    const time1 = new Date(timeString1);
    const [hours, minutes, seconds] = timeString2.split(/:|\s/).map(Number);
    const time2 = new Date();
    time2.setHours(hours + (timeString2.includes("pm") && hours !== 12 ? 12 : 0));
    time2.setMinutes(minutes);
    time2.setSeconds(seconds);

    const timeDifference = Math.abs(time2.getTime() - time1.getTime());
    const minutesDifference = Math.floor(timeDifference / (1000 * 60));
    const hoursDifference = Math.floor(minutesDifference / 60);
    const daysDifference = Math.floor(hoursDifference / 24);
    const remainingHours = hoursDifference % 24;
    const remainingMinutes = minutesDifference % 60;

    let output = "";
    if (daysDifference > 0) {
        // output += daysDifference + " day" + (daysDifference > 1 ? "s" : "") + " ";
        output += daysDifference + " day" + (daysDifference > 1 ? "s" : "") + " ";
    }
    if (remainingHours > 0) {
        output += remainingHours + " hr" + (remainingHours > 1 ? "s" : "") + " ";
    }
    if (remainingMinutes > 0) {
        output += remainingMinutes + " min" + (remainingMinutes > 1 ? "s" : "");
    }
    if (output === "") {
        output = "Less than a minute";
    }

    // console.log(output);

    return (
        <section className='handleFirstSectionOfIntro'>
            <div className='container overflow-hidden mx-auto'>
                <div className=' d-flex justify-content-center align-items-center flex-column flex-md-row'>
                    <div>
                        <h2 className='titleIntro mb-3'>UTI Staking Platform Managed With <span className='text-info-grad'>Automated Smartcontract</span> To Elevate Your Daily ROI%</h2>
                        {(!user?.walletAddress || user?.walletAddress === undefined || user?.walletAddress === "undefined") ? <Button variant="success" className='connectWallet' onClick={() => openWalletModalWarning()}>Get Started</Button> : <Button variant="success" className='connectWallet' onClick={() => navigate("/dashboard")}>Get Started</Button>}

                    </div>
                    <div>
                        <img src="/assets/banner.png" alt="" className='handleImg' width={700} />
                    </div>
                </div>
                <img src="/assets/minirobot.png" alt="" width={125} className='handleMini sliding' />
                <div className='handleIntroJoined'>

                    {/* <div className='triDiv' /> */}
                    <img src="/assets/joinedbox.png" alt="" width={350} className='overflow-hidden ' />
                    <div className='alignTextInfo text-light overflow-hidden'>
                        <p className='mb-0'><strong>Recently Joined</strong></p>
                        <p className='mb-0'>{(latestUserSign?.walletAddress)?.slice(0, 9)}xxxxxx</p>
                        <p className='mb-0'>{output} Ago</p>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default IntroSection;