import React from 'react';

const Affiliate = () => {
    return (
        <div className='container mx-auto mt-4 text-dark mb-5 pb-5'>
            <h3 className='text-center'>Affiliate Program</h3>
            <div className='my-5'>
                <div className='mb-4'>
                    <h5 className='fw-bolder pb-2'>Referral Introduction:</h5>
                    <p>Participants in the program have the opportunity to introduce new participants as their referrals</p>
                </div>
                <div className='mb-4'>
                    <h5 className='fw-bolder pb-2'>Referral Deposits:</h5>
                    <p>When a referred participant makes a deposit, the referrer receives a commission equal to 10% of that deposit.
                    </p>
                </div>
                <div className='mb-4'>
                    <h5 className='fw-bolder pb-2'>Referral Commissions:</h5>
                    <p>Referrers continue to receive 10% commissions on the earnings made by their direct referrals throughout their lifetime, regardless of the source of those earnings.
                    </p>
                    <p className='text-center'>This referral system allows for an unlimited number of branches within the referral. Each participant can earn commissions from the deposits and earnings of their direct referrals. The commission flow continues for a lifetime, ensuring ongoing earnings for referrers based on the activities of their referrals.
                    </p>
                </div>
            </div>

            <div>
                <h5 className='fw-bolder pb-2'>Tier Upgrades and Daily ROI Percentage:
                </h5>
                <div className='d-flex justify-content-center align-items-center'>
                    <div>

                        <img src="/assets/affiliatep1v2.png" alt="" className='img-fluid' />
                    </div>
                    <div>

                        <img src="/assets/affiliatep2v2.png" alt="" className='img-fluid' />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Affiliate;