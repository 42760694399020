import { useContext } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { AdminContext } from '../../Contexts/AdminContext';
const AdminRoutes = ({ children }) => {
    const { admin } = useContext(AdminContext);
    let location = useLocation();
    if (admin?.role === "admin") {
        return children;
    }
    return <Navigate to="/admin/login" state={{ from: location }} />;

};

export default AdminRoutes;