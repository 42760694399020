import React from 'react';
import './AffiliatePart.css';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';

const AffiliatePart = () => {
    return (
        <section className='bg-AffiliateUser min-vh-100 mt-5'>
            <div className='container mt-5 overflow-hidden'>
                <h3 className='text-center titleOfCentral'>Affiliate Program</h3>
                <div className=''>
                    {/* 
                <div className="position-relative circle1"><img src="/assets/circle.png" alt="" className='img-fluid position-absolute top-0 start-50 translate-middle' width={150} /></div> */}
                    <div className='py-5'>
                        <div className='row gy-5'>
                            <div className='col-12 col-sm-12 col-md-6 col-lg-4  mx-auto'>
                                <div className='cardOfAffiliate mx-auto text-center px-2'>

                                    <p className='mb-0'><strong><span className='color-grad-affiliate'>Daily Income: 0.1-0.5%</span><br />Get Daily RoI Up to 0.5 % By Increasing Your Tiers</strong></p>

                                </div>
                            </div>

                            <div className='col-12 col-sm-12 col-md-6 col-lg-4 mx-auto'>
                                <div className='cardOfAffiliate mx-auto text-center px-2'>

                                    <p className='mb-0'><strong><span className='color-grad-affiliate'>Referral Income: 10%</span><br />Refer to get instant 10%  of each referral deposit</strong></p>

                                </div>
                            </div>

                            <div className='col-12 col-sm-12 col-md-6 col-lg-4 mx-auto'>
                                <div className='cardOfAffiliate mx-auto text-center px-2'>

                                    <p className='mb-0'><strong><span className='color-grad-affiliate'>Reward Income: 10%</span><br />Get 10% rewards of all earning from your referrals.</strong></p>

                                </div>
                            </div>

                        </div>
                    </div>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: "center",
                            alignItems: "center",
                            flexWrap: 'wrap',
                            '& > :not(style)': {
                                m: 1,
                                width: "450px",
                                height: "450px",
                                zIndex: 200
                            },
                        }}

                    >

                        <Paper elevation={3} className='py-5 px-0 px-sm-0 px-md-3'>
                            <div>
                                <p className='fw-bold text-center'>Refer to Tier up your Daily ROI percentage</p>
                                <div className='row pt-4 mx-auto text-center fw-bold'>
                                    <div className='col'>
                                        <h5 className='pb-3 affSemiTitle'>Direct Refer</h5>
                                        <p>0-2</p>
                                        <p>3</p>
                                        <p>5</p>
                                        <p>7</p>
                                        <p>10 or More</p>
                                    </div>
                                    <div className='col'>
                                        <h5 className='pb-3 affSemiTitle'>Daily ROI</h5>
                                        <p>0.1%</p>
                                        <p>0.2%</p>
                                        <p>0.3%</p>
                                        <p>0.4%</p>
                                        <p>0.5%</p>
                                    </div>
                                    <div className='col'>
                                        <h5 className='pb-3 affSemiTitle'>Tier</h5>
                                        <p>Tier 1</p>
                                        <p>Tier 2</p>
                                        <p>Tier 3</p>
                                        <p>Tier 4</p>
                                        <p>Tier 5</p>
                                    </div>
                                </div>
                                {/* <div className='pt-4 fw-bold px-3'>
                                <p className='mb-0 pb-2'><small style={{ fontSize: "12px" }}>Referral Income : Refer to get instant 10% of each referral deposit</small></p>
                                <p><small style={{ fontSize: "12px" }}>Reward Income : Get 10% rewards of all earning from your referrals.</small></p>
                            </div> */}
                            </div>
                        </Paper>
                    </Box>

                    {/* 
                <div className="position-relative circle2"><img src="/assets/circle.png" alt="" className='img-fluid position-absolute top-0 start-50 translate-middle' width={150} /></div> */}
                </div>
            </div>
        </section>
    );
};

export default AffiliatePart;