import React, { useEffect } from 'react';
import IntroSection from './IntroSection';
import StepsToStake from './StepsToStake';
import AffiliatePart from './AffiliatePart';
import SuperDapps from './SuperDapps';
import AskQue from './AskQue';
import MediaSection from './MediaSection';

const Home = () => {
    // console.log(navigator.userAgentData);
    return (
        <div>
            <IntroSection />
            <SuperDapps />
            <StepsToStake />
            <AffiliatePart />
            {/* <MediaSection /> */}
            <AskQue />
        </div>
    );
};

export default Home;