import { useContext, useEffect } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { USDStakeContext } from '../../Contexts/USDContext';
import Loader from '../Loader/Loader';

const ProfileProtected = ({ children }) => {
    const { user, openWalletModalWarning, closeWalletModalWarning, walletWarning, walletLoading } = useContext(USDStakeContext);
    let location = useLocation();
    // console.log("load", walletLoading);
    if (walletLoading) {
        return <Loader />
    }
    else {
        if (!user.walletAddress) {

            if (walletLoading) {
                return <Loader />
            }
            else {
                return <Navigate to="/" state={{ from: location }} replace />;
            }
        }
        else if (user.walletAddress) {

            return children;
        }
    }


};

export default ProfileProtected;