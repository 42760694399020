import axios from 'axios';
import { createContext, useEffect, useState } from "react";

export const AdminContext = createContext();

export default function AdminProvider({ children }) {
    const [admin, setAdmin] = useState(null);
    // console.log(localStorage)
    useEffect(() => {
        const findAdmin = async () => {
            await axios.get("https://api.utistaking.com/api/v1/admin/admin", {
                headers: {
                    'authorization': `Bearer ${localStorage.getItem('adminusdstaketestnet')}`
                }
            })
                .then(res => {
                    if (res.status === 200) {
                        setAdmin(res.data.admin);

                    }
                })
                .catch(err => {
                    setAdmin(null);
                });
        }
        findAdmin();
    }, []);


    const logout = () => {
        setAdmin(null);
        localStorage.removeItem("adminusdstaketestnet");
    }

    return (
        <AdminContext.Provider value={{
            admin,
            setAdmin,
            logout,
        }}>{children}</AdminContext.Provider>
    )
}