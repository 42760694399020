import React from 'react';
import './AskQue.css';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AddIcon from '@mui/icons-material/Add';

const AskQue = () => {
    return (
        <section className='mb-5 overflow-hidden' style={{ backgroundColor: "#F3F6F9" }}>
            <div className='row'>
                <div className='col-3'>
                    <img src="/assets/ask2nd.png" alt="" className='img-fluid' />
                </div>
                <div className='col-6'>
                    <h3 className='text-center titleOfCentral pt-5 handleSmTitleCentral'>Frequently Asked Questions</h3>
                </div>
                <div className='col-3'>

                </div>
            </div>
            <div className='container'>
                <div style={{ marginTop: "0px", marginBottom: "0px" }}>
                    <Accordion className='my-4'>
                        <AccordionSummary
                            expandIcon={<AddIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                            className='bg-handle-with-text'
                        >
                            <Typography>What is UTI staking ?</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                UTI (Unitic) staking refers to the process of holding or locking up UTI tokens in a cryptocurrency wallet or platform to earn additional rewards or interest.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion className='my-4'>
                        <AccordionSummary
                            expandIcon={<AddIcon />}
                            aria-controls="panel2a-content"
                            id="panel2a-header2"
                            className='bg-handle-with-text'
                        >
                            <Typography>Why polygon smart chain ?</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                UTI (Unitic) on polygon Chain Network Due To Faster Transaction speed, Lower Gas Fees, polygon Network Compatibility, Smart Contract Compatibility.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion className='my-4'>
                        <AccordionSummary
                            expandIcon={<AddIcon />}
                            aria-controls="panel2a-content"
                            id="panel2a-header5"
                            className='bg-handle-with-text'
                        >
                            <Typography>What are Reward Income ?</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                Reward Income are direct credited to your wallet when your referrals claim UTI of there stake.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion className='my-4'>
                        <AccordionSummary
                            expandIcon={<AddIcon />}
                            aria-controls="panel2a-content"
                            id="panel2a-header4"
                            className='bg-handle-with-text'
                        >
                            <Typography>What are Referral Income ?</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                Referral Income are generated based on your referrals. For each referrals, 10% of deposited UTI is direct credited to your wallet.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion className='my-4'>
                        <AccordionSummary
                            expandIcon={<AddIcon />}
                            aria-controls="panel2a-content"
                            id="panel2a-header3"
                            className='bg-handle-with-text'
                        >
                            <Typography>How will the Daily ROI gets credited ?</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                Based on tiers your daily ROI gets differed. Once you reach 10% of stake deposit, you would be eligible for claim.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                </div>
            </div>
            <div className='ms-auto text-end'>
                <img src="/assets/ask1st.png" alt="" className='img-fluid imgRespo' />
            </div>
        </section>
    );
};

export default AskQue;