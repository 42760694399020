import React from 'react';
import './SuperDapps.css';

const SuperDapps = () => {
    return (
        <section className='bg-SuperDapps  tempSpace'>
            <div className='container'>
                <h3 className='text-center titleOfCentral controlledMargin'>Smart DApps Revolutionising Staking</h3>

                <div>
                    <div className='row gy-5'>
                        <div className='col-12 col-sm-12 col-md-6 col-lg-4 mx-auto'>
                            <div className='cardDapps mx-auto p-3'>
                                <img src="/assets/dapps1.png" alt="" className='img-fluid w-25 ' />
                                <h4><strong>UTI (Unitic)</strong></h4>
                                <p className='mb-0 text-center'>Unitic Token on the Polygon Network for Fast transactions, low fees, and a vibrant ecosystem. Stake our native token for rewards, participate in governance, and unlock its full potential. Experience a rewarding journey with UTI.
                                </p>
                            </div>
                        </div>
                        <div className='col-12 col-sm-12 col-md-6 col-lg-4 mx-auto'>
                            <div className='cardDapps mx-auto p-3'>
                                <img src="/assets/dapps2.png" alt="" className='img-fluid w-25 ' />
                                <h4><strong>Smart Contract</strong></h4>
                                <p className='mb-0 text-center'>Smart contracts are programmable agreements that enable secure and transparent staking transactions, removing the need for intermediaries and ensuring trust in decentralised networks.</p>
                            </div>
                        </div>
                        <div className='col-12 col-sm-12 col-md-6 col-lg-4 mx-auto'>
                            <div className='cardDapps mx-auto p-3'>
                                <img src="/assets/dapps3.png" alt="" className='img-fluid w-25 ' />
                                <h4><strong>DApps</strong></h4>
                                <p className='mb-0 text-center'>DApps in staking, powered by smart contracts, provide users with automated strategies for optimising their staking rewards and mitigating risks, ensuring a seamless staking experience.</p>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </section>
    );
};

export default SuperDapps;