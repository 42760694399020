import React from 'react';
import './StepsToStake.css';

const StepsToStake = () => {
    return (
        <section style={{ backgroundColor: "#F3F6F9" }}>
            <div className='container my-5'>
                <h3 className='text-center titleOfCentral'>4 Steps to Stake UTI (Polygon Network)</h3>

                <div className='mt-5'>

                    <div className='d-flex justify-content-start align-items-center mt-3' style={{ gap: "25px" }}>
                        <div>
                            <div className='stakeBgRound rounded-circle'>
                                <img src="/assets/wallet1.png" className='img-fluid p-3' width={150} alt="" />
                            </div>
                            <div class="mx-auto text-center"><span className='vertical-line'><span className='brd'></span></span></div>
                        </div>
                        <p className='mb-0 pb-0 ' style={{ fontWeight: 800 }}>Connect Wallet With<br />UTI (Polygon Network) in your wallet</p>
                    </div>

                    <div className='lineShape my-0'></div>
                    {/* <hr className='lineShape mx-auto my-0' /> */}

                    <div className='d-flex justify-content-end align-items-center my-0' style={{ gap: "25px" }}>
                        <p className='mb-0 pb-0 ' style={{ fontWeight: 800 }}>Transfer UTI<br />Enter & Transfer Multiples of 5000 UTI to stake and approve</p>
                        <div>
                            <div class="mx-auto text-center"><span className='vertical-line'><span className='brd'></span></span></div>
                            <div className='stakeBgRound rounded-circle'>
                                <img src="/assets/wallet2.png" className='img-fluid p-3' width={150} alt="" />
                            </div>
                            <div class="mx-auto text-center"><span className='vertical-line'><span className='brd'></span></span></div>
                        </div>
                    </div>
                    <div className='lineShape my-0'></div>
                    {/* <hr className='lineShape mx-auto my-0' /> */}

                    <div className='d-flex justify-content-start align-items-center my-0' style={{ gap: "25px" }}>
                        <div>
                            <div class="mx-auto text-center"><span className='vertical-line'><span className='brd'></span></span></div>
                            <div className='stakeBgRound rounded-circle'>
                                <img src="/assets/wallet3.png" className='img-fluid p-3' width={150} alt="" />
                            </div>
                            <div class="mx-auto text-center"><span className='vertical-line'><span className='brd'></span></span></div></div>
                        <p className='mb-0 pb-0 ' style={{ fontWeight: 800 }}>Stake Successful<br />start and get Total ROI upto 200%</p>
                    </div>
                    <div className='lineShape my-0'></div>
                    {/* <hr className='lineShape mx-auto my-0' /> */}

                    <div className='d-flex justify-content-end align-items-center my-0' style={{ gap: "25px" }}>
                        <p className='mb-0 pb-0 ' style={{ fontWeight: 800 }}>Claim Earnings<br />Withdraw Anytime Once Reached Eligibility Of 10% Of Your Deposit</p>
                        <div>

                            <div class="mx-auto text-center"><span className='vertical-line'><span className='brd'></span></span></div>
                            <div className='stakeBgRound rounded-circle'>
                                <img src="/assets/wallet4.png" className='img-fluid p-3' width={150} alt="" />
                            </div>
                        </div>
                    </div>

                </div>

            </div>
        </section>
    );
};

export default StepsToStake;