import axios from 'axios';
import { useContext, useEffect, useState } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import './AdminDashboard.css';
import swal from "sweetalert";


const AdminDashboard = () => {
  const [allAdmin, setAllAdmin] = useState([]);
  const [userItem, setUserItem] = useState([]);
  const [totalAmount, setTotalAmount] = useState(0);
  const [totalWithdrawAmount, setTotalWithdrawAmount] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const admin = async () => {
      await axios.get('https://api.utistaking.com/api/v1/admin/')
        .then(res => {
          setAllAdmin(res.data);
          // console.log(res.data)
        })
    };
    const dataUser = async () => {
      await axios.get('https://api.utistaking.com/api/v1/stake/all')
        .then(res => {
          setUserItem(res.data?.data);
          // console.log(res.data)
        })
    }

    const fetching = async () => {
      await axios.get(`https://api.utistaking.com/api/v1/stake/all`)
        .then(res => {
          if (res.status === 200) {
            // console.log(res.data?.data);
            const totalInvestedAmount = res.data?.data.reduce((sum, item) => sum + parseFloat(item?.INR), 0);
            const totalWithdrawAmount = res.data?.data.reduce((sum, item) => sum + parseFloat(item?.claimedAmount), 0);
            // console.log("withdraw", totalWithdrawAmount);
            // const totalEarned = res.data?.data.reduce((sum, item) => sum + item.totalEarned, 0);
            setTotalAmount(() => totalInvestedAmount);
            setTotalWithdrawAmount(() => totalWithdrawAmount);
            // console.log(totalEarned, "t");
            // setSumStake(() => totalInvestedAmount);
            // setTotalEarned(() => totalEarned);
            // let summing = res.data?.data.filter(item => item.investedAmount)
          }
        })
        .catch((e) => {
          console.log(e);
        })
        .finally(() => {
        })
    }
    admin();
    dataUser();
    fetching();
  }, [])

  const handleClickOpenAdmin = () => {
    navigate('/admin/adminUser')
  }
  const handleClickSeller = () => {
    navigate('/admin/users-ia');
  }
  const handleClickWithdraw = () => {
    navigate('/admin/users-wa');
  }


  //   const Logout = () => {
  //     logOut();
  //     swal({
  //       // title: "S",
  //       text: "You have successfully logout.",
  //       icon: "success",
  //       button: "OK!",
  //       className: "modal_class_success",
  //     });
  //   }

  return (
    <div>
      <div className='container titlesregistered'>
        <h3 className='text-white text-start'>DASHBOARD</h3>
        <Row xs={1} md={2} lg={3} xl={4} className="g-4">

          <Col onClick={handleClickOpenAdmin}>
            <Card className='cardDash '>
              <Card.Body className="d-flex align-items-center justify-content-between">
                <Card.Text className='dashboardTxt'>
                  <p>Admins</p>
                  <h3 className='text-start'>{allAdmin.length}</h3>
                </Card.Text>
                <div className="iconDas">
                  <p className='text-white coinsIcon'><i class="fas fa-users"></i></p>
                </div>
              </Card.Body>
            </Card>
          </Col>

          <Col onClick={handleClickSeller}>
            <Card className='cardDash '>
              <Card.Body className="d-flex align-items-center justify-content-between">
                <Card.Text className='dashboardTxt'>
                  <p>Total Users: </p>
                  <h3 className='text-start'>{userItem?.length}</h3>
                </Card.Text>
                <div className="iconDas">
                  <p className='text-white coinsIcon'><i class="fas fa-users"></i></p>
                </div>
              </Card.Body>
            </Card>
          </Col>
          <Col onClick={handleClickSeller}>
            <Card className='cardDash '>
              <Card.Body className="d-flex align-items-center justify-content-between">
                <Card.Text className='dashboardTxt'>
                  <p>Total Investment: </p>
                  <h3 className='text-start'>{totalAmount} USDT</h3>
                </Card.Text>
                <div className="iconDas">
                  <p className='text-white coinsIcon'><i class="fas fa-money-check-alt"></i></p>
                </div>
              </Card.Body>
            </Card>
          </Col>
          <Col onClick={handleClickSeller}>
            <Card className='cardDash '>
              <Card.Body className="d-flex align-items-center justify-content-between">
                <Card.Text className='dashboardTxt'>
                  <p>To be Given: </p>
                  <h3 className='text-start'>{totalAmount * 2} USDT</h3>
                </Card.Text>
                <div className="iconDas">
                  <p className='text-white coinsIcon'><i class="fas fa-money-check-alt"></i></p>
                </div>
              </Card.Body>
            </Card>
          </Col>
          <Col onClick={handleClickWithdraw}>
            <Card className='cardDash '>
              <Card.Body className="d-flex align-items-center justify-content-between">
                <Card.Text className='dashboardTxt'>
                  <p>Withdraw: </p>
                  <h3 className='text-start'>{totalWithdrawAmount}</h3>
                </Card.Text>
                <div className="iconDas">
                  <p className='text-white coinsIcon'><i class="fas fa-money-check-alt"></i></p>
                </div>
              </Card.Body>
            </Card>
          </Col>
          <Col onClick={handleClickSeller}>
            <Card className='cardDash '>
              <Card.Body className="d-flex align-items-center justify-content-between">
                <Card.Text className='dashboardTxt'>
                  <p>Balance: </p>
                  <h3 className='text-start'>{userItem?.length}</h3>
                </Card.Text>
                <div className="iconDas">
                  <p className='text-white coinsIcon'><i class="fas fa-money-check-alt"></i></p>
                </div>
              </Card.Body>
            </Card>
          </Col>
          {/* <Col onClick={handleClickSeller}>
            <Card className='cardDash '>
              <Card.Body className="d-flex align-items-center justify-content-between">
                <Card.Text className='dashboardTxt'>
                  <p>Sells History: </p>
                  <h2 className='text-start'>{sellItem?.length}</h2>
                </Card.Text>
                <div className="iconDas">
                  <p className='text-white coinsIcon'><i class="fas fa-dot-circle"></i></p>
                </div>
              </Card.Body>
            </Card>
          </Col> */}


        </Row>
      </div>
    </div>
  );
};

export default AdminDashboard;