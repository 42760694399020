import logo from './logo.svg';
import React, { useContext, useEffect } from 'react';
import './App.css';
import Header from './Component/Header/Header';
import { Route, Routes, useLocation } from 'react-router-dom';
import toast, { Toaster } from 'react-hot-toast';
import Home from './Pages/Home/Home';
import Footer from './Component/Footer/Footer';
import Dashboard from './Pages/Dashboard/DashboardComponent/Dashboard';
import DashboardFooter from './Pages/Dashboard/DashboardComponent/DashboardFooter/DashboardFooter';
import DashboardHome from './Pages/Dashboard/DashboardPages/DashboardHome/DashboardHome';
import DashboardStake from './Pages/Dashboard/DashboardPages/DashboardStake/DashboardStake';
import DashboardMyStaking from './Pages/Dashboard/DashboardPages/DashboardMyStaking/DashboardMyStaking';
import DashboardMyStakingDetails from './Pages/Dashboard/DashboardPages/DashboardMyStaking/DashboardMyStakingDetails';
import DashboardReferrals from './Pages/Dashboard/DashboardPages/DashboardReferrals/DashboardReferrals';
import Affiliate from './Pages/Affiliate/Affiliate';
import WalletLogin from './Component/WalletModal/WalletLogin';
import ProfileProtected from './Component/ProtectedRoutes/ProfileProtected';
import WalletLoginWaring from './Component/WalletModal/WalletloginWarning';
import HomeAffiliate from './Pages/Home/HomeAffiliate';
import Login from './Pages/Login/Login';
import Forgetpassword from './Pages/Login/Forgetpassword';
import Otp from './Pages/Login/Otp';
import ResetPassword from './Pages/Login/ResetPassword';
import AdminRoutes from './Component/ProtectedRoutes/AdminRoutes';
import AdminDashboard from './Pages/AdminV1Dashboard/AdminDashboard/AdminDashboard';
import DashboardAdminEditProfile from './Pages/AdminV1Dashboard/Admins/DashboardAdminEditProfile';
import Admins from './Pages/AdminV1Dashboard/Admins/Admins';
import DashboardUsersIA from './Pages/AdminV1Dashboard/Users/DashboardUsersIA';
// import DashboardSellHistory from './Pages/AdminV1Dashboard/Sells/DashboardSellHistory';
// import DashboardSellsView from './Pages/AdminV1Dashboard/Sells/DashboardSellsView';
import AdminDashboardHome from './Pages/AdminV1Dashboard/AdminDashboardHome';
import { USDStakeContext } from './Contexts/USDContext';
import { RingLoader } from 'react-spinners';
import swal from 'sweetalert';
import DashboardWithdrawHistory from './Pages/AdminV1Dashboard/Users/DashboardWithdrawHistory';

function App() {
  const pathname = useLocation().pathname;
  const onHideDashboard = pathname.includes("/dashboard");
  const onHideDashboardAdmin = pathname.includes("/admin");

  return (
    <>
      {!onHideDashboardAdmin && <Header />}
      <WalletLogin />
      <WalletLoginWaring />
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/home' element={<Home />} />
        <Route path='/:affiliateLink' element={<HomeAffiliate />} />
        <Route path='/affiliate' element={<Affiliate />} />

        {/* Admin  */}
        <Route path="/admin/login" element={<Login />} />
        <Route path="/admin/forgetpassword" element={<Forgetpassword />} />
        <Route path="/admin/otp/:token" element={<Otp />} />
        <Route path="/admin/reset-password/:token" element={<ResetPassword />} />

        {/* Admin Dashboard  */}

        <Route path="/admin" element={
          <AdminRoutes>
            <AdminDashboardHome />
          </AdminRoutes>
        }>
          <Route index element={<AdminDashboard />} />
          <Route path='dashboard' element={<AdminDashboard />} />
          <Route path="/admin/adminprofile/:id" element={<DashboardAdminEditProfile />} />
          <Route path='adminUser' element={<Admins />} />

          <Route path="users-ia" element={<DashboardUsersIA />} />
          <Route path="users-wa" element={<DashboardWithdrawHistory />} />
          {/* <Route path="sellhistory" element={<DashboardSellHistory />} /> */}
          {/* <Route path="/admin/sell/action/:id" element={<DashboardSellsView />} /> */}

        </Route>

        {/* Dashboard nested routes */}
        <Route path='/dashboard' element={
          <ProfileProtected>
            <Dashboard />
          </ProfileProtected>
        } >
          <Route index element={<DashboardHome />} />
          <Route path='dashboard-home' element={<DashboardHome />} />
          <Route path='stake' element={<DashboardStake />} />
          {/* <Route path='samy-stakes' element={<DashboardMyStaking />} /> */}
          <Route path='my-stake' element={<DashboardMyStakingDetails />} />
          <Route path='referrals' element={<DashboardReferrals />} />
        </Route>

      </Routes>
      <Toaster position="top-center"
        reverseOrder={false}
        gutter={8}
        containerClassName=""
        containerStyle={{}}
        toastOptions={{
          // Define default options
          className: '',
          duration: 3000,
          style: {
            background: '#363636',
            color: '#fff',
            maxWidth: '100%'
          },

          // Default options for specific types
          success: {
            duration: 3000,
            theme: {
              primary: 'green',
              secondary: 'black',
            },
          },
        }} />
      {(!onHideDashboard && !onHideDashboardAdmin) && <Footer />}
    </>
  );
}

export default App;
