import React, { useContext, useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Tabs, { tabsClasses } from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import './Dashboard.css';
import { NavLink, Outlet, useNavigate } from 'react-router-dom';
import { styled, alpha } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import EditIcon from '@mui/icons-material/Edit';
import Divider from '@mui/material/Divider';
import ArchiveIcon from '@mui/icons-material/Archive';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import DashboardFooter from './DashboardFooter/DashboardFooter';
import { USDStakeContext } from '../../../Contexts/USDContext';

const StyledMenu = styled((props) => (
    <Menu
        elevation={0}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}
        {...props}
    />
))(({ theme }) => ({
    '& .MuiPaper-root': {
        borderRadius: 6,
        marginTop: theme.spacing(1),
        minWidth: 100,
        color:
            theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
        boxShadow:
            'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
        '& .MuiMenu-list': {
            padding: '4px 0',
        },
        '& .MuiMenuItem-root': {
            '& .MuiSvgIcon-root': {
                fontSize: 18,
                color: theme.palette.text.secondary,
                marginRight: theme.spacing(1.5),
            },
            '&:active': {
                backgroundColor: alpha(
                    theme.palette.primary.main,
                    theme.palette.action.selectedOpacity,
                ),
            },
        },
    },
}));

const Dashboard = () => {
    const [value, setValue] = useState();
    const { user, getBalanceTestnet } = useContext(USDStakeContext);
    const navigate = useNavigate();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    useEffect(() => {
        if (!user.walletAddress) {
            navigate("/");
        }
        getBalanceTestnet();
    }, [user.walletAddress]);

    useEffect(() => {
        const selectedTab = localStorage.getItem('selectedTab');
        if (selectedTab) {
            setValue(parseInt(selectedTab));
        }
        else {
            setValue(0);
        }
    }, []);
    // useEffect(() => {
    //     localStorage.setItem('selectedTab', value.toString());
    // }, [value]);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleChange = (event, newValue) => {
        setValue(newValue);
        localStorage.setItem('selectedTab', newValue.toString());

    };

    return (
        <section>
            <div className='dHeader' >
                {/* <div className='mx-auto text-center'>
                    <img src="./assets/linev2.png" className='img-fluid' alt="" />
                </div> */}

                {
                    window.innerWidth <= 600 ? <div className='d-flex justify-content-center align-items-center pt-2'>
                        <div>
                            <Button
                                id="demo-customized-button"
                                aria-controls={open ? 'demo-customized-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={open ? 'true' : undefined}
                                variant="contained"
                                disableElevation
                                onClick={handleClick}
                                className='mx-auto'
                                endIcon={<KeyboardArrowDownIcon />}
                            >
                                Dashboard
                            </Button>
                            <StyledMenu
                                id="demo-customized-menu"
                                MenuListProps={{
                                    'aria-labelledby': 'demo-customized-button',
                                }}
                                anchorEl={anchorEl}
                                open={open}
                                onClose={handleClose}
                            >
                                <MenuItem onClick={handleClose} disableRipple>
                                    <NavLink className="navcss" to="dashboard-home">Dashboard</NavLink>
                                </MenuItem>
                                <MenuItem onClick={handleClose} disableRipple>
                                    <NavLink className="navcss" to="stake">Stake</NavLink>
                                </MenuItem>
                                <MenuItem onClick={handleClose} disableRipple>
                                    <NavLink className="navcss" to="my-stake">My Staking</NavLink>
                                </MenuItem>
                                <MenuItem onClick={handleClose} disableRipple>
                                    <NavLink className="navcss" to="referrals">Referrals</NavLink>
                                </MenuItem>
                            </StyledMenu>
                        </div>
                    </div> :

                        <Box sx={{ width: '100%', bgcolor: 'transparent', }}>
                            <Tabs value={value} onChange={handleChange} centered
                                className='pt-2'
                                sx={{
                                    '& .MuiTabs-indicator': {
                                        background: 'linear-gradient(92.18deg, #066A98 -2.82%, #BB07A0 99.39%);',
                                    },
                                    '& .MuiTab-root': {
                                        color: '#000000',
                                        textTransform: "capitalize",
                                        fontSize: "18px"
                                    },
                                    '& .MuiTab-root.Mui-selected': {
                                        color: '#000000',
                                        fontWeight: "800"
                                    }

                                }}>
                                <Tab label="Dashboard" onClick={() => navigate("dashboard-home")} />
                                <Tab label="Stake" onClick={() => navigate("stake")} />
                                <Tab label="My Staking" onClick={() => navigate("my-stake")} />
                                <Tab label="Referrals" onClick={() => navigate("referrals")} />
                            </Tabs>
                        </Box>
                }
                <div className='mt-5'>
                    <Outlet />
                    {/* <DashboardFooter /> */}
                </div>
            </div>
        </section>
    );
};

export default Dashboard;